import { FirebaseError, initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getAnalytics } from 'firebase/analytics';
import { AUTH_ERRORS } from "./errorMessages";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
getAnalytics(app);

const logInWithEmailAndPassword = async (email, password) => {
  await signInWithEmailAndPassword(auth, email, password);
};

const saveEarlyAccessEmail = async (email) => {
  const collectionName = "early-access-users";
  const q = query(collection(db, collectionName), where("email", "==", email));
  const result = await getDocs(q);
  if (result.empty) {
    try {
      await addDoc(collection(db, collectionName), {
        email
      });
    } catch (err) {
      throw new FirebaseError(AUTH_ERRORS.INVALID_EMAIL, err.message);
    }
  } else {
    throw new FirebaseError(AUTH_ERRORS.EMAIL_IN_USE, "Email in use");
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  const docRef = await addDoc(collection(db, "users"), {
    uid: "",
    name,
    authProvider: "local",
    email,
  });

  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);

    const user = res.user;
    await updateDoc(docRef, {
      uid: user.uid,
    });
  } catch (err) {
    await deleteDoc(docRef);
    throw err;
  }
};

const sendPasswordReset = async (email) => {
  await sendPasswordResetEmail(auth, email);
};

const logout = async () => {
  try {
    if (auth === undefined || auth === null) {
      return;
    }
    await signOut(auth);
  } catch (err) {
    console.log(JSON.parse(JSON.stringify(err)));
  }
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  saveEarlyAccessEmail,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
