import React, { useState } from "react";
import { FirebaseError } from "firebase/app";
import { Alert, AlertTitle, Button, FormControl, FormHelperText, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AUTH_ERRORS, getMessageFromError } from "../../Firebase/errorMessages";
import { saveEarlyAccessEmail } from "../../Firebase";

import './ComingSoon.css';

const ComingSoon = () => {
    const [email, setEmail] = useState("");
    const [formError, setFormError] = useState("");
    const [alert, setAlert] = useState(false);

    const words = ['make', 'design', 'debug', 'build', 'publish'];

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = validateEmail(email);

        try {
            if (!isValid) {
                throw FirebaseError(AUTH_ERRORS.INVALID_EMAIL, "Invalid email");
            }
            await saveEarlyAccessEmail(email);
            setEmail("");
            setFormError("")
            setAlert(true)
            setTimeout(() => {
                setAlert(false);
            }, 5000);
        } catch (err) {
            const msg = getMessageFromError(err);
            setFormError(msg);
        }
    };

    const emailUpdated = (e) => {
        const { value } = e.target;
        setEmail(value);
    }

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
                borderBottom: '.5rem dotted #000',
                borderTop: '.5rem dotted #000',
                '& > :not(style)': {
                    width: '100%',
                    backgroundSize: 'cover',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    backgroundPosition: '110px',
                    backgroundRepeat: 'none'
                },
            }}
        >
            <div>
                <Paper elevation={3} />
                {alert && <Alert severity="success">
                    <AlertTitle>Done</AlertTitle>
                    You're all signed up — <strong>stay tuned!</strong>
                </Alert>}
                <Typography component="h1" variant="h1" mx={{ xs: 5 }} mt={{ md: 12, sm: 5, xs: 2 }} zIndex="99" color="primary.main">
                    I want to
                    <div className="linebreak hide-md"></div>
                    <div className="slidingVertical">
                        {
                            words.map(word =>
                                <span key={word}>
                                    <Typography className="inner-word" component="h1" variant="h1" color='secondary.main'>{word}</Typography>
                                </span>
                            )
                        }
                    </div>
                    <span className="linebreak hide-lg"></span>
                    <span className="a-game">a game.</span>
                </Typography>
                <Typography component="p" variant="h5" mx={{ xs: 5 }} mt={{ xs: 3, md: 1 }} color="primary.main" marginTop={-3}>
                    Have you ever said, <i>I want to make a game</i> to a friend, a colleague, a partner, a random stranger, a mirror, or a pet? Yes? Oh, then you have come to the right place, friend.
                </Typography>
                <Typography component="p" variant="h5" mx={{ xs: 5 }} mt={{ xs: 3, md: 2 }} color="primary.main" marginTop={-3}>
                    Read how-tos, tips, and insights written by other game creators or write your own articles in areas around game design, debugging, innovating, building, and publishing.
                </Typography>
                <Box mx={{ xs: 5 }} mt={{ xs: 5 }} maxWidth={'400px'}>
                    <form onSubmit={handleSubmit}>
                        <FormControl fullWidth >
                            <TextField
                                value={email}
                                error={formError ? true : false}
                                onChange={emailUpdated}
                                label="Sign up for early access"
                                variant="outlined"
                                fullWidth
                                id="email"
                                aria-describedby="my-helper-text"
                            />
                            <FormHelperText id="email-helper-text">{formError}</FormHelperText>
                        </FormControl>
                        <FormControl>
                            <Button onSubmit={handleSubmit} type="submit" variant="contained" style={{ marginTop: "1rem" }} color="secondary">Submit</Button>
                        </FormControl>
                    </form>
                </Box>
                <br />
            </div>
        </Box>
    )
}

export default ComingSoon;