import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Login from "./Pages/Login";
// import Register from "./Pages/Register";
// import Reset from "./Pages/Reset";
// import Home from "./Pages/Home";
import ComingSoon from "./Pages/ComingSoon";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={<ComingSoon />} />
          {/* <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/home" element={<Home />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
