
// AUTH ERRORS:
// https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth


export const AUTH_ERRORS = {
    EMAIL_IN_USE: "auth/email-already-in-use",
    INVALID_EMAIL: "auth/invalid-email",
    OPERATION_NOT_ALLOWED: "auth/operation-not-allowed",
    WEAK_PASSWORD: "auth/weak-password",
    USER_DISABLED: "auth/user-disabled",
    USER_NOT_FOUND: "auth/user-not-found",
    WRONG_PASSWORD: "auth/wrong-password"
};

export const ERROR_MESSAGES = {
    "auth/email-already-in-use": "Email in use",
    "auth/invalid-email": "Invalid Email",
    "auth/operation-not-allowed": "Operation not allowed",
    "auth/user-disabled": "User account disabled",
    "auth/too-many-requests": "Too many requests",
    "auth/weak-password": "Invalid credentials",
    "auth/user-not-found": "User not found",
    "auth/wrong-password": "Invalid credentials",
};

export const getMessageFromError = (err) => {
    const rawMessage = err.code;
    console.error(err);
    if (ERROR_MESSAGES[rawMessage] !== undefined) {
        return ERROR_MESSAGES[rawMessage]
    } else {
        return "Error making request";
    }
}