import { createTheme } from '@mui/material/styles';
import { indigo } from '@mui/material/colors';


// possible colors: amber, blue, blueGrey, 
// brown, common, cyan, deepOrange, deepPurple, 
// green, grey, indigo, lightBlue, lightGreen, 
// lime, orange, pink, purple, red, teal, yellow
const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    white: {
      main: '#fff',
      contrastText: '#222',
    },
    secondary: {
      main: indigo[300]
    }
  },
  typography: {
    fontFamily: ['Georgia', 'Times', "Times New Roman", 'serif']
  }
});

theme.typography.h1 = {
  fontSize: '1.2rem',
  '@media (min-width:0)': {
    fontSize: '3rem',
    lineHeight: '3.3rem'
  },
  '@media (min-width:330px)': {
    fontSize: '4rem',
    lineHeight: '4.3rem'
  },
  '@media (min-width:500px)': {
    fontSize: '5rem',
    lineHeight: '5.2rem'
  },
  '@media (min-width:600px)': {
    fontSize: '6rem',
    lineHeight: '6.4rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '7rem',
    lineHeight: '7.4rem'
  },
};


theme.typography.h3 = {
  fontFamily: 'Roboto',
  '@media (min-width:0)': {
    fontSize: '1.7rem',
    lineHeight: '2rem'
  },
  '@media (min-width:300px)': {
    fontSize: '2.5rem',
    lineHeight: '2.7rem'
  },
  '@media (min-width:500px)': {
    fontSize: '3rem',
    lineHeight: '3rem'
  },
};


theme.typography.h4 = {
  fontFamily: 'Roboto',
  fontSize: '1rem',
  '@media (min-width:0)': {
    fontSize: '1.5rem',
    lineHeight: '1.7rem'
  },
  '@media (min-width:300px)': {
    fontSize: '2rem',
    lineHeight: '2.2rem'
  },
  '@media (min-width:500px)': {
    fontSize: '2.5rem',
    lineHeight: '2.7rem'
  },
};

theme.typography.h5 = {
  '@media (min-width:0)': {
    fontSize: '1rem'
  },
  '@media (min-width:300px)': {
    fontSize: '1.2rem'
  },
  '@media (min-width:600px)': {
    fontSize: '1.5rem'
  },
};
export default theme;